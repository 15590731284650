<template>
  <div>
    <v-card outlined>
      <v-card-text>
        <v-row align="center">
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="3"
            xl="3"
          >
            <v-select
              :disabled="updatingChannel || updatingPeriod"
              :loading="updatingPeriod"
              class="white"
              outlined
              hide-details
              dense
              :label="$t('period')"
              :items="periodOptions"
              item-value="key"
              item-text="label"
              v-model="period"
            >
              <template v-slot:selection="{ item }">
                <span v-if="item.key === 'custom'">
                  <span v-if="customTimeAndDateFormatted()">
                    {{ customTimeAndDateFormatted() }}
                  </span>
                  <span v-else>
                    {{ item.label }}
                  </span>
                </span>
                <span v-else>
                  {{ item.label }}
                </span>
              </template>
              <template v-slot:append-outer>
                <v-tooltip
                  v-if="period === 'custom'"
                  top
                >
                  <template v-slot:activator="{ on, attrs }">
                    <v-icon
                      @click="showDashDateDialog = true"
                      v-on="on"
                      v-bind="attrs"
                    >
                      mdi-calendar
                    </v-icon>
                  </template>s
                  <span>
                    {{ $t('changeCustomPeriod') }}
                  </span>
                </v-tooltip>
              </template>
            </v-select>
          </v-col>
          <v-col cols="12" sm="12" md="12" lg="3" xl="3">
            <v-select
              multiple
              :disabled="updatingChannel || updatingPeriod"
              :loading="updatingChannel"
              class="white"
              outlined
              hide-details
              dense
              chips
              small-chips
              deletable-chips
              :placeholder="$t('all')"
              persistent-placeholder
              :label="$t('channels')"
              :items="channels"
              item-value="uuid"
              item-text="label"
              v-model="channel"
            >
              <template v-slot:item="data">
                <div class="d-flex justify-start align-center" style="width: 100%;">
                  <!-- Checkbox Indicator -->
                  <v-checkbox
                    :input-value="channel.includes(data.item.uuid)"
                    :disabled="true"
                    class="shrink mr-2"
                  ></v-checkbox>
                  <span class="flex-grow-1">{{ data.item.label }}</span>
                  <v-chip v-if="data.item.label !== 'All' && !data.item.active" small outlined class="ml-2">Disabled</v-chip>
                </div>
              </template>
            </v-select>
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="3"
            xl="3"
          >
            <v-select
              :disabled="updatingChannel || updatingPeriod"
              hide-details
              outlined
              dense
              :label="$t('timeOfDay')"
              :items="timeOfDayOptions"
              item-text="label"
              item-value="key"
              v-model="timeOfDay"
            />
          </v-col>
          <v-col
            cols="12"
            sm="12"
            md="12"
            lg="3"
            xl="3"
            class="text-end"
          >
            <div>
              <v-btn
                color="#67AD5B"
                class="white--text"
                depressed
                @click="showScheduledReportsOverlay"
              >
                {{ $t('scheduledReports') }}
              </v-btn>
            </div>
            <v-btn
              color="#67AD5B"
              class="white--text mt-2"
              depressed
              @click="showSendDashReportDialog"
            >
              {{ $t('send') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <draggable
      class="row"
      :list="dashes"
      :move="positionChanged"
      :options="draggableOptions"
    >
      <template v-for="(dash, i) in dashes">
        <v-col
          class="draggable pb-0"
          :key="dash.uuid"
          :data-dash-uuid="dash.uuid"
          cols="12"
          :md="dash.cols"
          sm="12"
        >
          <DashTable
            v-if="collection.dashes[i].renderType === 'table'"
            :collection="collection"
            v-model="collection.dashes[i]"
            :first="i === 0"
            :last="i === dashes.length - 1"
            @decreaseOrder="decreaseOrder(i, collection.dashes[i])"
            @increaseOrder="increaseOrder(i, collection.dashes[i])"
          />
          <DashChart
            v-if="collection.dashes[i].renderType === 'gchart'"
            :collection="collection"
            v-model="collection.dashes[i]"
            :first="i === 0"
            :last="i === dashes.length - 1"
            @decreaseOrder="decreaseOrder(i, collection.dashes[i])"
            @increaseOrder="increaseOrder(i, collection.dashes[i])"
          />
          <DashSingleValue
            v-if="collection.dashes[i].renderType === 'singleValue'"
            :collection="collection"
            v-model="collection.dashes[i]"
            :first="i === 0"
            :last="i === dashes.length - 1"
            @decreaseOrder="decreaseOrder(i, collection.dashes[i])"
            @increaseOrder="increaseOrder(i, collection.dashes[i])"
          />
        </v-col>
      </template>
    </draggable>
    <CustomDashDateRange
      v-if="showDashDateDialog"
      :collection="collection"
      :start-time-in-millis="collection.startTimeInMillis"
      :end-time-in-millis="collection.endTimeInMillis"
      @onConfirm="updateTimeAndDateRange"
      @onCancel="showDashDateDialog = !showDashDateDialog"
    />
  </div>
</template>

<script>
import Draggable from 'vuedraggable'

export default {
	name: 'DashCollection',
	props: {
		collection: {
			type: Object,
			default: () => {}
		}
	},
	components: {
		'DashSingleValue': () => import('@/components/dash/DashSingleValue'),
		'DashChart': () => import('@/components/dash/DashChart'),
		'DashTable': () => import('@/components/dash/DashTable'),
		Draggable,
    'CustomDashDateRange': () => import('@/components/dash/CustomDashDateRange'),
	},
	data: () => ({
		draggableOptions: {
			draggable: '.draggable',
			handle: '.draggable-item'
		},
		showNotice: false,
		updatingChannel: false,
		updatingPeriod: false,
		showCustomTimeInterval: false,
		showDashDateDialog: false

	}),
  watch: {
    period(newValue) {
      if(newValue === 'custom') {
        this.showDashDateDialog = true
      }
    }
  },
	methods: {
		showSendDashReportDialog() {
			this.$store.commit('updateGenericDialog', {
        'component': 'components/dash/SendDashReportForm',
        'props': {
          dashCollection: this.collection,
					startTimeInMillis: this.startTimeInMillis,
					endTimeInMillis: this.endTimeInMillis
        }
      })
		},
		updateTimeAndDateRange(timeRange) {
      this.showDashDateDialog = !this.showDashDateDialog
      this.updatingPeriod = true

      const payloadObject = {
        uuid: this.collection.uuid,
        name: this.collection.name,
        data: JSON.stringify(this.collection.data),
        period: this.period,
				startTimeInMillis: null,
				endTimeInMillis: null,
      }

			if (timeRange.startTimeInMillis !== null && timeRange.startTimeInMillis !== undefined && timeRange.endTimeInMillis !== null && timeRange.endTimeInMillis !== undefined ) {
				payloadObject.startTimeInMillis = timeRange.startTimeInMillis
				payloadObject.endTimeInMillis = timeRange.endTimeInMillis
        payloadObject.period = 'custom'

        // We must update startTimeInMillis and endTimeInMillis in store so that the dashes themselves fetch data correctly
        this.$store.commit('updateStartTimeInMillis', timeRange.startTimeInMillis)
        this.$store.commit('updateEndTimeInMillis', timeRange.endTimeInMillis)
			}

			this.$store.dispatch('updateDashCollection', payloadObject)
				.then(updatedDashCollection => {
					this.$store.commit('updateDashCollection', updatedDashCollection)
				})
				.finally(() => {
					this.updatingPeriod = false
				})
		},
		positionChanged(e) {
			// ATTN. It is possible to use @change rather than :move to handle moving dash card on drop rather on move
			let list = e.relatedContext.list
			let elementIndex = e.draggedContext.index
			let element = e.draggedContext.element
			let futureIndex = e.draggedContext.futureIndex
			let newList = list.slice(0, elementIndex).concat(list.slice(elementIndex+1))

			newList.splice(futureIndex, 0, element)

			this.$store.dispatch('updateDashOrder', newList.map((listItem, index) => {
				return {
					uuid: listItem.uuid,
					weight: index
				}
			}))

			// SINGLE ELEMENT UPDATE
			// this.$store.dispatch('updateDash', {
			// 	uuid: e.draggedContext.element.uuid,
			// 	weight: e.draggedContext.futureIndex
			// })
		},
		decreaseOrder(index, element) {
			let list = this.dashes
			let oldIndex = index
			let newIndex = index - 1
			let newList = list.slice(0, oldIndex).concat(list.slice(oldIndex+1))

			newList.splice(newIndex, 0, element)

			this.$store.dispatch('updateDashOrder', newList.map((listItem, index) => {
				return {
					uuid: listItem.uuid,
					weight: index
				}
			})).then(() => {
				this.$store.dispatch('getDashCollections')
			})
		},
		increaseOrder(index, element) {
			let list = this.dashes
			let oldIndex = index
			let newIndex = index + 1
			let newList = list.slice(0, oldIndex).concat(list.slice(oldIndex+1))

			newList.splice(newIndex, 0, element)

			this.$store.dispatch('updateDashOrder', newList.map((listItem, index) => {
				return {
					uuid: listItem.uuid,
					weight: index
				}
			})).then(() => {
				this.$store.dispatch('getDashCollections')
			})
		},
		closeNotice() {
			this.showNotice = false
		},
		updateStartTime(value) {
			this.startTimeInMillis = value
		},
		updateEndTime(value) {
			this.endTimeInMillis = value
		},
    customTimeAndDateFormatted() {
      if(this.collection.startTimeInMillis !== null && this.collection.startTimeInMillis !== undefined && this.collection.endTimeInMillis !== null && this.collection.endTimeInMillis !== undefined) {
        return 'From ' + this.$moment(this.collection.startTimeInMillis).format('DD-MM-YYYY') + ' to ' + this.$moment(this.collection.endTimeInMillis).format('DD-MM-YYYY')
      }
      return ''
    },
    clearCustomTimeAndDate () {
      this.updatingPeriod = true

      this.$store.dispatch('updateDashCollection', {
        uuid: this.collection.uuid,
        name: this.collection.name,
        data: JSON.stringify(this.collection.data),
        period: this.period === 'custom' ? 'current_month' : this.period,
        startTimeInMillis: null,
        endTimeInMillis: null
      })
        .then(() => {
          this.$store.dispatch('getDashCollections')
        })
        .finally(() => {
          this.updatingPeriod = false
        })
    },
    hoursToTimeOfDay(hoursFrom, hoursTo) {
      if(typeof hoursFrom !== 'undefined' && hoursFrom !== null && hoursFrom !== '' && typeof hoursTo !== 'undefined' && hoursTo !== null && hoursTo !== '') {
        const hoursFromSplit = hoursFrom.split(':')
        const hoursFromHours = Number(hoursFromSplit[0])
        const hoursFromMinutes = Number(hoursFromSplit[1])
        const hoursToSplit = hoursTo.split(':')
        const hoursToHours = Number(hoursToSplit[0])
        const hoursToMinutes = Number(hoursToSplit[1])

        if(!isNaN(hoursFromHours) && !isNaN(hoursFromMinutes) && !isNaN(hoursToHours) && !isNaN(hoursToMinutes)) {
          // Morning
          if(hoursFromHours >= 6 && hoursToHours < 10) {
            return 'morning'
          }
          // Noon
          if(hoursFromHours >= 10 && hoursToHours < 15) {
            return 'noon'
          }
          // Evening
          if(hoursFromHours >= 15 && hoursToHours <= 23 && hoursToHours <= 59) {
            return 'evening'
          }

          // Else value is a custom value
          return 'custom'
        }
      }
      return 'all-day'
    },
    timeOfDayToHours(timeOfDay) {
      switch (timeOfDay) {
        case 'all-day':
          return {
            timeIntervalFrom: null,
            timeIntervalTo: null
          }
        case 'morning':
          return {
            timeIntervalFrom: '06:00',
            timeIntervalTo: '09:59'
          }
        case 'noon':
          return {
            timeIntervalFrom: '10:00',
            timeIntervalTo: '14:59'
          }
        case 'evening':
          return {
            timeIntervalFrom: '15:00',
            timeIntervalTo: '23:59'
          }
        default:
          return null
      }
    },
    showScheduledReportsOverlay() {
      this.$store.commit('updateContextOverlay', {
        'component': 'components/dash/ScheduledReportsCard',
        'props': {
          dashCollection: this.collection
        }
      })
    }
	},
	computed: {
		startTimeInMillis() {
			let startTimeInMillis = 0
			const date = new Date()

			switch(this.collection.period) {
					case 'today':
							date.setUTCHours(0)
							date.setUTCMinutes(0)
							date.setUTCMinutes(0)
							date.setUTCMilliseconds(0)
							startTimeInMillis = date.getTime()
							break
					case 'yesterday':
							date.setUTCDate(date.getUTCDate() - 1)
							date.setUTCHours(0)
							date.setUTCMinutes(0)
							date.setUTCSeconds(0)
							date.setUTCMilliseconds(0)
							startTimeInMillis = date.getTime()
							break
					case 'current_week':
							date.setUTCDate(1)
							startTimeInMillis = this.$moment().startOf('isoweek').valueOf()
							break
					case 'previous_month':
							date.setUTCMonth(date.getUTCMonth() - 1)
							startTimeInMillis = this.$moment().subtract(1,'months').startOf('month').valueOf()
							break
					case 'previous_week':
							date.setUTCDate(2)
							startTimeInMillis = this.$moment().subtract(1,'week').startOf('isoweek').valueOf()
							break
					case 'custom':
							startTimeInMillis = this.collection.startTimeInMillis
							break
					default:
							startTimeInMillis = this.$moment().startOf('month').valueOf()

			}

			return startTimeInMillis
	},
	endTimeInMillis() {
			let endTimeInMillis = 0
			const date = new Date()
			switch(this.collection.period) {
					case 'today':
							date.setUTCHours(23)
							date.setUTCMinutes(59)
							date.setUTCMilliseconds(59)
							date.setUTCSeconds(59)
							endTimeInMillis = date.getTime()
							break
					case 'yesterday':
							date.setUTCDate(date.getUTCDate() - 1)
							date.setUTCHours(23)
							date.setUTCMinutes(59)
							date.setUTCSeconds(59)
							date.setUTCMilliseconds(59)
							endTimeInMillis = date.getTime()
							break
					case 'current_week':
							date.setUTCDate(1)
							endTimeInMillis = this.$moment().endOf('isoweek').valueOf()
							break
					case 'previous_month':
							date.setUTCMonth(date.getMonth() - 1)
							endTimeInMillis = this.$moment().subtract(1,'months').endOf('month').valueOf()
							break
					case 'previous_week':
							date.setUTCDate(2)
							endTimeInMillis = this.$moment().subtract(1,'week').endOf('isoweek').valueOf()
							break
					case 'custom':
							endTimeInMillis = this.collection.endTimeInMillis
							break
					default:
							endTimeInMillis = this.$moment().endOf('month').valueOf()
			}

			return endTimeInMillis
	},
		dashes() {
			return this.collection.dashes
		},
		periodOptions() {
			return [
				{
					label: this.$t('today'),
					key: 'today'
				},
				{
					label: this.$t('yesterday'),
					key: 'yesterday'
				},
				{
					label: this.$t('currentMonth'),
					key: 'current_month'
				},
				{
					label: this.$t('currentWeek'),
					key: 'current_week'
				},
				{
					label: this.$t('previousMonth'),
					key: 'previous_month'
				},
				{
					label: this.$t('previousWeek'),
					key: 'previous_week'
				},
        {
					label: this.$t('custom') + (this.customTimeAndDateFormatted() ? ' ' + this.$t('selectAgainToChange') : ''),
					key: 'custom'
				}
			]
		},
    timeOfDayOptions() {
      return [
        {
          label: this.$t('allDay') + ' (00:00 - 23:59)',
          key: 'all-day'
        },
        {
          label: this.$t('morning') + ' (06:00 - 09:59)',
          key: 'morning'
        },
        {
          label: this.$t('noon') + ' (10:00 - 14:59)',
          key: 'noon'
        },
        {
          label: this.$t('evening') + ' (15:00 - 23:59)',
          key: 'evening'
        },
        {
          label: this.$t('customComingSoon'),
          key: 'custom',
          disabled: true
        }
      ]
    },
		period: {
			set(value) {
				this.updatingPeriod = true
				const payload = {
					uuid: this.collection.uuid,
					name: this.collection.name,
					data: JSON.stringify(this.collection.data),
					period: value
				}

				this.$store.dispatch('updateDashCollection', payload)
						.then(updatedDashCollection => {
							this.$store.commit('updateDashCollection', updatedDashCollection)
						})
						.finally(() => {
							this.updatingPeriod = false
						})
			},
			get() {
				return this.collection.period || 'current_month'
			}
		},
    timeOfDay: {
      get() {
        return this.hoursToTimeOfDay(this.collection.timeIntervalFrom, this.collection.timeIntervalTo)
      },
      set(value) {
        const timeIntervalValues = this.timeOfDayToHours(value)

        if(timeIntervalValues) {
          this.updatingPeriod = true
          const payload = {
            uuid: this.collection.uuid,
            name: this.collection.name,
            data: JSON.stringify(this.collection.data),
            timeIntervalFrom: timeIntervalValues.timeIntervalFrom,
            timeIntervalTo: timeIntervalValues.timeIntervalTo,
            endTimeInMillis: this.collection.endTimeInMillis,
            startTimeInMillis: this.collection.startTimeInMillis
          }        

          this.$store.dispatch('updateDashCollection', payload).then(updatedDashCollection => {
            this.$store.commit('updateDashCollection', updatedDashCollection)
          }).finally(() => {
            this.updatingPeriod = false
          })
        }
      }
    },
		channel: {
			set(value) {
				this.updatingChannel = true
				this.$store.dispatch('updateDashCollection', {
					uuid: this.collection.uuid,
					name: this.collection.name,
					data: JSON.stringify(this.collection.data),
					channels: value
				})
						.then(updatedDashCollection => {
							this.$store.commit('updateDashCollection', updatedDashCollection)
						})
						.finally(() => {
							this.updatingChannel = false
						})
			},
			get() {
				return this.collection.channels || ['']

			}
		},
		location: {
			set(value) {
				this.$store.dispatch('updateDashCollection', {
					uuid: this.collection.uuid,
					name: this.collection.name,
					location: value
				})
			},
			get() {
				return this.collection.location
			}
		},
		channels() {
			return this.$store.state.channels
		},
		locations() {
			return this.$store.state.locations
		},
	},
}
</script>
